<template>
    <div>
        <ul class="menus">
            <li v-for="item in menus" :key="item.id">
                <a :href="`#${item.id}`" @click="toggleClass(item.id)" :class="{'active': activeClasses[item.id]}">
                    <span class="h4" v-if="item.h == 3">#{{item.title}}</span>
                    <span class="h5" v-if="item.h == 4">#{{item.title}}</span>
                    <span class="h6" v-if="item.h == 5">#{{item.title}}</span>
                    <span class="h7" v-if="item.h == 6">#{{item.title}}</span>
                </a>
                <ul v-if="item[childLabel]" class="child">
                    <menu-tree :menus="item[childLabel]" :child-label="childLabel"></menu-tree>
                </ul>
            </li>
        </ul>
    </div>

</template>

<script>
    export default {
        name: "menuTree",
        props: {
            menus: {
                type: [Object,Array],
                required: true
            },
            childLabel: {
                type: String,
                default: 'child'
            }
        },
        data() {
            return{
                activeClasses: {}
            }
        },
        methods: {
            toggleClass(itemId) {
                // this.$set(this.activeClasses, itemId, !this.activeClasses[itemId]);
                this.activeClasses = {}; // 清除所有链接的active类
                this.$set(this.activeClasses, itemId, true); // 将点击的链接设置为active
            }
        }
    }
</script>

<style scoped lang="less">
    .active {
        color: #409EFF;
    }
    .menus{
        line-height: 1.7em;
        a:hover {
            color: #409EFF;
        }
    }
    .child{
        padding-left: 15px;
    }
    .h4 {
        font-size: 0.96em;
        font-weight: bold;
    }
     .h5 {
        font-size: 0.87em;
        font-weight: bold;
    }
    .h6 {
        font-size: 0.78em;
        font-weight: bold;
    }
    .h7 {
        font-size: 0.69em;
        font-weight: bold;
    }
</style>
